<script setup lang="ts">
const isOpen = defineModel<boolean>({ default: false })
const auth = useAuth()

const { data } = useUsers({
  itemsPerPage: 20,
  page: 1,
})

const options = computed(() => {
  if (!data.value) return []
  return data.value?.hits.map((user) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.mbtvId,
  }))
})

const selected = ref(auth.session.user?.mbtvId)

watch(isOpen, () => {
  if (isOpen) {
    selected.value = auth.session.user?.mbtvId
  }
})
</script>

<template>
  <UModal v-model="isOpen">
    <div class="min-h-32 flex h-32 items-center justify-center">
      <USelect
        v-model="selected"
        :options="options"
        @update:model-value="() => auth.signIn(selected ? Number.parseInt(selected.toString()) : 0)"
      />
    </div>
  </UModal>
</template>
