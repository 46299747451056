<script lang="ts" setup>
const route = useRoute()

const auth = useAuth()

const userSelectModelIsOpen = ref(false)

const navigation = computed(() => {
  const menuPoints = [
    {
      name: 'Leseplan-Kategorien',
      href: '/kategorien',
      current: route.path.startsWith('/kategorien'),
    },
    {
      name: 'Leseplan-Reviews',
      href: '/reviews',
      current: route.path.startsWith('/reviews'),
    },
  ]

  if (auth.session.isModerator || auth.session.isAdmin) {
    menuPoints.unshift({
      name: 'Alle Lesepläne',
      href: '/leseplaene',
      current:
        route.path.startsWith('/leseplaene') &&
        route.query.onlyOwn !== 'true' &&
        route.query.highlight !== 'true',
    })
  }

  if (auth.session.isCreator) {
    menuPoints.unshift({
      name: 'Meine Lesepläne',
      href: '/leseplaene?filter&onlyOwn=true',
      current: route.path.startsWith('/leseplaene') && route.query.onlyOwn === 'true',
    })
  }

  if (auth.session.isAdmin) {
    menuPoints.push({
      name: 'Nutzer',
      href: '/users',
      current: route.path.startsWith('/users'),
    })
    menuPoints.unshift({
      name: 'Highlighted Lesepläne',
      href: '/leseplaene?filter&highlight=true&refetch=1',
      current: route.path.startsWith('/leseplaene') && route.query.highlight === 'true',
    })
  }

  return menuPoints
})
</script>

<template>
  <div class="min-h-full">
    <header>
      <nav class="border-b border-gray-200 bg-white">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="flex justify-between py-2">
            <div class="flex">
              <div class="flex flex-shrink-0 items-center">
                <img class="mt-1 block h-16 w-auto" src="/logo.svg" alt="BibelTV Logo" />
              </div>
              <div class="flex items-center gap-8 sm:-my-px sm:ml-6">
                <NuxtLink
                  v-for="item in navigation"
                  :key="item.name"
                  :href="item.href"
                  class="inline-flex items-center border-b-2 px-1 pt-1 text-base font-medium"
                  :class="[
                    item.current
                      ? 'border-bibeltv-500 text-gray-900'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  ]"
                  :aria-current="item.current ? 'page' : undefined"
                >
                  {{ item.name }}
                </NuxtLink>
              </div>
            </div>
            <div class="hidden sm:ml-6 sm:flex sm:items-center">
              <!-- Profile dropdown -->
              <div class="relative ml-3">
                <div>
                  <div
                    class="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span
                      class="absolute -inset-1.5 cursor-pointer"
                      @click="
                        () => {
                          userSelectModelIsOpen = true
                        }
                      "
                    />
                    fsef
                    <span class="sr-only">Open user menu</span>
                    <img
                      v-if="auth.session.user"
                      class="h-8 w-8 rounded-full"
                      :src="auth.session.user.profilePictureUrl ?? undefined"
                      :alt="auth.session.user.firstName ?? undefined"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <UserSelectModal v-model="userSelectModelIsOpen" />
    </header>

    <div class="py-10">
      <main>
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>
